var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isStandalone
    ? _c("router-view")
    : _vm.$user
    ? _c(
        "div",
        { staticClass: "main-layout", attrs: { id: "app" } },
        [
          _vm._m(0),
          _c(
            "main",
            { staticClass: "main-content", class: { "open-nav": _vm.wideNav } },
            [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "page-content" },
                [
                  _vm.sidePanelData
                    ? _c("SidePanel", {
                        attrs: { "panel-data": _vm.sidePanelData },
                      })
                    : _vm._e(),
                  _c(
                    "transition",
                    { attrs: { name: "toRight", mode: "out-in" } },
                    [_c("router-view", { key: _vm.$route.params.id })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Scroll to top",
                      expression: "'Scroll to top'",
                    },
                  ],
                  staticClass: "scroll-top",
                  class: { visible: _vm.scrollTopVisible },
                  attrs: { "aria-label": "Scroll to top", type: "button" },
                  on: { click: _vm.scrollToTop },
                },
                [_c("BaseIcon", { attrs: { name: "caret_up" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("MainNav", { attrs: { "nav-items": _vm.navItems } })
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("MainHeader")
  },
]
render._withStripped = true

export { render, staticRenderFns }